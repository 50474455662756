<template>
  <el-row>
    <el-container style="height:calc(93.5vh);">
      <el-header class="header">
        售卡
      </el-header>
      <el-container style="height:calc(93.5vh);">
        <el-container>
          <el-main class="main">
            <el-row class="content" :gutter="10">
              <el-col :span="rowCol" v-for="(item,i) in callList" :key="i" >
                <div class="card cursor">
                  <div class="card-top">
                    <div class="card-title">
                      <div class="name">{{  item.name}}</div>
                      <div class="price"><b>¥{{ item.original_price}}</b></div>
                    </div>
                    <div class="card-info">
                      项目：{{ item.server_ids }}
                    </div>
                    <div class="card-time">
                      <div class="time">
                        有效期限：{{item.valid}}个月
                      </div>
                      <div class="num">
                        {{item.num}}次
                      </div>
                    </div>
                  </div>
                  <div class="card-btm">
                    <div class="card-type">
                      限时卡
                    </div>
                    <div class="stepper">
                      <el-input-number v-model="stepperNum" :precision="0" @change="stepperChange" :min="1" :max="99">
                      </el-input-number>
                    </div>
                  </div>
                </div>
              </el-col>
            </el-row>


          </el-main>
        </el-container>
      </el-container>
      <el-footer class="footer">
        <div class="left">
          <span>共5项</span><span>合计：<b>¥399.00</b></span>
          <el-input placeholder="填写备注" prefix-icon="el-icon-edit" v-model="markInput" clearable>
          </el-input>
        </div>
        <div class="right">
          <el-button type="danger" round @click="confirmChangeGoods">收款</el-button>
        </div>
      </el-footer>
    </el-container>
  </el-row>
</template>

<script>
  export default {
    data() {
      return {
        innerWidth: document.documentElement.clientWidth,
        rowCol: 6,
        markInput: '',
        stepperNum: 1,
        num:20,
        callList:[]
      }
    },
    watch: {
      innerWidth(val) {
        if (val >= 1440) {
          console.log(this.rowCol);
          this.rowCol = 6
        } else if (val >= 1150) {
          this.rowCol = 8
        } else if (val >= 830) {
          this.rowCol = 12
        } else if (val <= 830) {
          this.rowCol = 12
        }
      }
    },
    mounted() {
      var that = this;
      if (that.innerWidth >= 1440) {
        this.rowCol = 6
      } else if (that.innerWidth >= 1150) {
        this.rowCol = 8
      } else if (that.innerWidth >= 830) {
        this.rowCol = 12
      } else if (that.innerWidth <= 830) {
        this.rowCol = 12
      }
      window.onresize = () => {
        return (() => {
          window.fullWidth = document.documentElement.clientWidth;
          that.innerWidth = window.fullWidth; // 宽
        })()
      };
      this.getCalllist()
    },
    methods: {
      //获取卡卷列表
      getCalllist(){
        this.$http.post('api/store/storecard/cardlist').then(res =>{
          this.callList = res.data.data
        })
      },
      stepperChange(value) {
        this.stepperNum = value
      },
      confirmChangeGoods() {
        this.$router.push({
          name: 'PaySettle',
          params: {
            type: 'sellCardList'
          }
        })
      },
    }
  }

</script>

<style lang="less" scoped>
  .header {
    line-height: 50px;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    background-color: #fff;
    border-bottom: 1px solid #e9e9e9;
  }

  .main {

    .content {
      padding: 0 10px 60px;

      .card {
        border: 3px solid #73a0fa;
        margin: 10px 0;
        border-bottom: none;

        .card-top {
          padding: 10px;


          .card-title,
          .card-time {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }

          .card-title {
            .name {
              font-size: 14px;
              font-weight: bold;
            }

            .price {
              font-size: 12px;
              color: #f56c6c;
            }
          }

          .card-info {
            font-size: 12px;
            color: #999;
            margin: 10px 0 15px;
          }

          .card-time {
            font-size: 12px;
            color: #999;
            margin-bottom: 15px;
          }
        }

        .card-btm {
          display: flex;
          justify-content: space-between;
          align-items: center;
          background-color: #73a0fa;
          font-weight: bold;
          color: #fff;
          padding: 10px;
          border-bottom: 3px dashed #fff;


          .stepper {

            /deep/ .el-input-number {
              width: 100px;
              height: 24px;
              display: flex;
              justify-content: flex-start;
              align-items: center;

              .el-input-number__decrease,
              .el-input-number__increase {
                width: 24px;
                height: 24px;
                box-sizing: border-box;
                z-index: 1;
                line-height: 20px;
                border-radius: 100%;
                border: 2px solid #fff;
                background-color: #73a0fa;
                color: #fff;
                font-weight: bold;
              }

              .el-input {
                width: 100px;
                height: 34px;

                .el-input__inner {
                  height: 24px;
                  line-height: 24px;
                  padding: 0 15px;
                  border-color: #73a0fa;
                  background-color: #73a0fa;
                  color: #fff;
                  font-weight: bold;
                  border: none;
                }
              }
            }
          }
        }
      }
    }
  }

  .footer {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 9999;
    height: 50px;
    width: calc(100vw - 81px);
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #eeeeee;


    .left {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 80%;
      min-width: 505px;

      span {
        margin-right: 5px;

        b {
          color: #f56c6c;
        }
      }

      /deep/ .el-input {
        width: 50%;
        min-width: 240px;
        margin-left: 30px;

        .el-input__inner {
          border: none;
          border-radius: 0;
          border-bottom: 1px solid #dcdfe6;

          &::placeholder {
            color: #999;
            font-size: 12px;
          }
        }

        .el-input__prefix {
          color: #999;
        }
      }
    }

    .right {
      width: 20%;
      text-align: right;

      /deep/ .el-button {
        width: 30%;
        min-width: 100px;
      }
    }
  }

  /deep/ .el-header {
    height: 50px !important;
  }

</style>
